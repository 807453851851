import { useState } from "react";

export const ANIMATION_DURATION = 1200;

function useAnimation() {
  const [animation, setAnimation] = useState({
    current: 0,
  });

  function animate(newCurrent, fromLeft) {
    // Starts the animation, slides color blocks in.
    setAnimation((state) => ({
      ...state,
      in: true,
      fromLeft,
      active: true,
    }));

    // Halfway, switch the current image, slides color blocks away.
    setTimeout(() => {
      setAnimation((state) => ({
        ...state,
        in: false,
        current: newCurrent,
        fromLeft: !fromLeft,
      }));
    }, ANIMATION_DURATION / 2);

    // End of the animation.
    setTimeout(() => {
      setAnimation((state) => ({
        ...state,
        active: false,
      }));
    }, ANIMATION_DURATION);
  }

  return [animation, animate];
}

export default useAnimation;
