import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import Icon from "../../Icon";
import ImageComponent, { ImageType } from "../../ImageComponent";
import { caption } from "../../Typography";
import useAnimation, { ANIMATION_DURATION } from "./animation";
import { getNextIndex, getPrevIndex } from "./utils";

const Wrapper = styled.div`
  margin: ${theme.space("m")} 0;

  ${mq("3")} {
    margin: ${theme.space("xxxl")} 0;
  }
`;

const CarouselWrapper = styled.div`
  box-sizing: content-box;
  display: flex;
  position: relative;
  height: 300px;

  ${mq("2")} {
    height: 520px;
  }

  ${mq("3")} {
    height: 768px;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  position: relative;

  ${mq("3")} {
    width: 30%;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: ${theme.color("primary")};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: ${`transform ${
      ANIMATION_DURATION / 3
    }ms cubic-bezier(.71,.26,.27,1)`};
    transform: scaleX(${({ animation }) => (animation.in ? 1 : 0)});
    transform-origin: ${({ animation }) =>
      animation.fromLeft ? "left" : "right"};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  opacity: 0.4;
  z-index: 1;
`;

const OverlayIcon = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

const arrowStyles = css`
  width: 2rem;
  height: 2rem;
  color: ${theme.color("primary")};
  cursor: pointer;
  margin: 0 auto;
  display: block;
  transition: transform 0.25s;
  ${mq("2")} {
    width: 3.125rem;
    height: 3.125rem;
  }
`;

const LeftArrow = styled(Icon)`
  ${arrowStyles};
  display: none;
  ${mq("3")} {
    display: block;
    margin-left: 5rem;
  }
  &:hover {
    transform: translateX(-5px);
  }
`;

const RightArrow = styled(Icon)`
  ${arrowStyles};
  transform: rotate(180deg);

  ${mq("3")} {
    margin-right: 5rem;
  }
  &:hover {
    transform: translateX(5px) rotate(180deg);
  }
`;

const StyledImage = styled(ImageComponent)`
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const PrevImageWrapper = styled(ImageWrapper)`
  width: 0;
  ${mq("3")} {
    width: 33%;
  }

  ${StyledImage} {
    img {
      object-position: 100% 50%;
    }
  }
`;

const CurrentImageWrapper = styled(ImageWrapper)`
  flex: 0 0 70%;
  margin-right: ${theme.space("xxs")};

  ${mq("3")} {
    flex: 0 0 60%;
    margin: 0 ${theme.space("xxs")};
  }
`;

const NextImageWrapper = styled(ImageWrapper)`
  ${StyledImage} {
    img {
      object-position: 0 50%;
    }
  }
`;

const ImageTitle = styled.div`
  ${caption};
  position: absolute;
  top: 100%;
  left: ${theme.space("s")};
  padding-top: ${theme.space("xxs")};

  ${mq("3")} {
    left: 0;
  }
`;

function ImageCarousel({ images, noCaptions, ...props }) {
  const [animation, animate] = useAnimation();
  const prev = getPrevIndex(animation.current, images);
  const next = getNextIndex(animation.current, images);
  return (
    <Wrapper {...props}>
      <CarouselWrapper>
        <PrevImageWrapper
          animation={animation}
          onClick={animation.active ? undefined : () => animate(prev, true)}
        >
          <Overlay />
          <OverlayIcon>
            <LeftArrow type="arrowSolo" />
          </OverlayIcon>
          <StyledImage {...images[prev]} layout="responsive" />
        </PrevImageWrapper>
        <CurrentImageWrapper animation={animation}>
          <StyledImage {...images[animation.current]} layout="responsive" />
          {!noCaptions && (
            <ImageTitle>{images[animation.current].title}</ImageTitle>
          )}
        </CurrentImageWrapper>
        <NextImageWrapper
          animation={animation}
          onClick={animation.active ? undefined : () => animate(next, false)}
        >
          <Overlay />
          <OverlayIcon>
            <RightArrow type="arrowSolo" />
          </OverlayIcon>
          <StyledImage {...images[next]} layout="responsive" />
        </NextImageWrapper>
      </CarouselWrapper>
    </Wrapper>
  );
}

ImageCarousel.propTypes = {
  noCaptions: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.shape(ImageType),
    })
  ),
};

export default ImageCarousel;
