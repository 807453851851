import { graphql } from "gatsby";
import { arrayOf, shape } from "prop-types";
import React from "react";

import { datoFile } from "../../types";
import Carousel from "../Carousel/ImageCarousel";
import VerticalMargin from "../VerticalMargin";

const ImageCarousel = ({ carouselImages, ...props }) => (
  <VerticalMargin {...props}>
    <Carousel images={carouselImages} />
  </VerticalMargin>
);

export const ImageCarouselType = {
  carouselImages: arrayOf(shape(datoFile)),
};

export const imageCarouselFragment = graphql`
  fragment imageCarouselFragment on DatoCMS_ImageCarouselRecord {
    type: __typename
    id
    carouselImages {
      data: responsiveImage(
        imgixParams: { fit: crop, h: 520, w: 848, fm: jpg, auto: format }
      ) {
        ...responsiveImageFragment
      }
    }
  }
`;

ImageCarousel.propTypes = ImageCarouselType;

export default ImageCarousel;
